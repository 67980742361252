import { readCookie } from "./cookies";
import { isStaff, configFor } from "./library";

function gtag() {
  if (window.dataLayer) {
    window.dataLayer.push(arguments);
  }
}

// optionalEventData is an object {}.
export function trackEvent(name, optionalEventData = {}) {
  gtag("event", name, optionalEventData);
}

const initTrackingsMadeTable = () => {
  return {
    googleAnalytics: false,

    // remarketing
    googleRemarketing: false,

    // conversion tracking
    googleConversion: false,
    facebookConversion: false,
    virtualPageViews: []
  };
};

export const trackVirtualPageView = (pagePath) => {
  const id = configFor("credentials.googleAnalyticsId");
  gtag("config", id, { page_path: pagePath });

  if (window.trackingsMade && window.trackingsMade.virtualPageViews) {
    window.trackingsMade.virtualPageViews.push(pagePath);
  }
};

function Tracker() {
  // TODO These cookie names would be better off stored in a single file for
  // global reference.
  const trackingId = readCookie("trackingId");
  const advancedTrackingEnabled =
    readCookie("advancedTrackingCookiesEnabled") == "true";
  const shouldTrack = !isStaff();
  const conversion = document.querySelector("[data-conversion]");

  const trackWithGoogleAnalytics = () => {
    initGoogleAnalytics();
    // Event for viewing a product OR sample in a certain category
    const defaultEventData = {
      product: configFor("userSession.currentProduct"),
      taxon: configFor("userSession.currentTaxon"),
      store: configFor("userSession.currentStore")
    };

    // Sales related
    if (window.location.pathname.match(/t\/.+/)) {
      trackEvent("view_item_list", defaultEventData);
    }

    if (window.location.pathname.match(/notes\/.+/)) {
      trackEvent("view_item", defaultEventData);
    }

    const addToCartButtons = document.querySelectorAll(
      "[data-add-to-cart-button]"
    );
    addToCartButtons.forEach((el) => {
      el.addEventListener("click", function () {
        trackEvent("add_to_cart", defaultEventData);
      });
    });

    // Authors related
    if (window.location.pathname.match(/sell_notes/)) {
      trackEvent("browsed_become_author_page");
    }

    // TODO: Use the URLs generated by Rails, if possible.
    if (window.location.pathname.match(/law_sellers/)) {
      trackEvent("browsed_uk_law_author_page");
    }
  };

  const trackGoogleConversion = (data) => {
    // TODO: Track other types of conversion
    if (data.conversion == "purchase") {
      trackEvent("purchase", {
        transaction_id: data.transactionId,
        value: data.value,
        currency: data.currency
      });

      window.trackingsMade.googleConversion = true;
    } else if (data.conversion == "author_application") {
      trackEvent("generate_lead", {
        store: configFor("userSession.currentStore")
      });

      window.trackingsMade.googleConversion = true;
    }
  };

  // Enables/disables add tracking as well as tracks pageview
  // with the config() call
  const initGoogleAnalytics = () => {
    const id = configFor("credentials.googleAnalyticsId");

    window.dataLayer = window.dataLayer || [];
    gtag("js", new Date());

    const config = {
      linker: {
        domains: ["oxbridgenotes.co.uk", "oxbridgenotes.com"]
      },
      page_location: location.href, // TurboLinks friendly
      allow_ad_personalization_signals: true,
      allow_google_signals: true,
      user_id: trackingId,
      anonymize_ip: true
    };

    if (advancedTrackingEnabled) {
      gtag("config", id, config);
      window.trackingsMade.googleAnalytics = true;
      window.trackingsMade.googleRemarketing = true;
    } else {
      gtag("config", id, {
        ...config,
        allow_ad_personalization_signals: false,
        allow_google_signals: false
      });
      window.trackingsMade.googleAnalytics = true;
    }
  };

  if (shouldTrack) {
    trackWithGoogleAnalytics();

    if (conversion) {
      const data = conversion.dataset;
      trackGoogleConversion(data);
    }
  }
}

export default function track() {
  window.trackingsMade = initTrackingsMadeTable();
  Tracker();
}
