export function setCookie(name, value, expire = 60 * 60 * 24 * 365) {
  var expireDate = new Date()
  expireDate.setSeconds(expireDate.getSeconds() + expire)
  document.cookie =
    name +
    "=" +
    escape(value) +
    "; expires=" +
    expireDate.toGMTString() +
    "; path=/"
}

export function readCookie(name) {
  var nameEQ = name + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function deleteCookie(name) {
  setCookie(name, "", -1)
}
