import { readCookie } from "./cookies"

export function isStaff() {
  return configFor("userSession.isStaff")
}

export function configFor(keyString) {
  if (!window.oxnotes) {
    return null
  }
  return accessNestedKeyByString(window.oxnotes, keyString)
}

export function railsEnv() {
  return configFor("settings.railsEnv")
}

function accessNestedKeyByString(object, string) {
  string = string.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
  string = string.replace(/^\./, "") // strip a leading dot
  var a = string.split(".")
  for (var i = 0, n = a.length; i < n; ++i) {
    var key = a[i]
    if (key in object) {
      object = object[key]
    } else {
      return null
    }
  }
  // Often this is a string, but it could be any object
  return object
}

// Useful for debugging
export function logProps(obj, objName) {
  var result = ""
  for (var i in obj) {
    result += objName + "." + i + " = " + obj[i] + "\n"
  }
  console.log(result)
}

export function getDataFromDOM(dataAttr, namespaceID = null) {
  const el = getDataElementWithinNamespace(dataAttr, namespaceID)
  if (el) {
    const data = el.dataset[camelize(dataAttr)]
    if (!data) {
      throw `Unexpected null for value of ${dataAttr}`
    }
    return data
  } else {
    return null
  }
}

export function isLoggedIn() {
  return readCookie("user_id")
}

export function getDataElementWithinNamespace(dataAttr, namespaceID = null) {
  const dataComponent = `[data-${dataAttr}]`
  const selector = namespaceID
    ? `#${namespaceID} ${dataComponent}`
    : dataComponent

  return document.querySelector(selector)
}

// Some browsers or browsing modes (e.g. Incognito) do not
// support this storage system.
export function isSessionStorageNameSupported() {
  const testKey = "test"
  try {
    const storage = window.sessionStorage
    storage.setItem(testKey, "1")
    storage.removeItem(testKey)
    return true
  } catch (error) {
    return false
  }
}

export function callOnDataAttribute(dataAttr, func) {
  const matchingEls = document.querySelectorAll(`[data-${dataAttr}]`)
  ;[].forEach.call(matchingEls, func)
}

export function attachScript(src) {
  const script = document.createElement("script")
  // script.async = true
  script.defer = true
  script.src = src
  document.body.appendChild(script)
}

export function get(url, callback = () => {}) {
  if (!url) {
    throw TypeError("Must provide a URL")
  }

  const xhr = new XMLHttpRequest()
  const async = true
  xhr.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      callback(xhr)
    }
  }
  xhr.open("GET", url, async)
  xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
  return xhr.send()
}

export function getJSON(url, callback) {
  return get(url, (xhr) => {
    callback(JSON.parse(xhr.responseText))
  })
}

function camelize(string) {
  return string.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase()
  })
}
